@font-face {
  font-family: "Akira-Expanded";
  src: url(/public/fonts/Akira-Expanded.otf);
}
@font-face {
  font-family: "Roc-Grotesk";
  src: url(/public/fonts/RocGrotesk.ttf);
}

body {
  background-color: #141414;
  color: #FFFFFF;
}

button {
  min-width: 150px;
}

a {
  color: #F44336;
  text-decoration: none;
}

/*body {*/
/*  margin: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  font-family: "Akira-Expanded", sans-serif;
  margin-top: 20px;
}

h4,
h5,
h6,
p {
  font-family: "RoboGrotesk", sans-serif;
}
p {
  font-size: 16px;
}

button {
  padding: 0 40px;
  background-color: #F44336;
  font-family: "RoboGrotesk", sans-serif;
  color: #FFFFFF;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 2px;
  border: 0;
  min-width: 80px;
  text-align: center;
  border-radius: 3px;
  height: 2.5rem;
}

label {
  padding: 12px 39px;
  margin: 0 4px;
}

a:active,
a:hover,
button:active,
button:hover,
label:hover {
  outline: 0;
  cursor: pointer;
}

button:hover {
  background-color: #b5160a;
}
button:disabled {
  color: #ffffff;
  background-color: #7e7979;
}
button:disabled:hover {
  cursor: not-allowed;
}

.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 70vh; */
  /* min-height: 600px; */
}

.welcome-message {
  margin-top: 100px;
  text-align: center;
}

.content {
  max-width: 600px;
  text-align: center;
  margin-top: 100px;
}

.error {
  color: red;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 10px;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  width: 100vw;
  /*background-color: #f00;*/
}

.background-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  animation: scaleIn 5s;
}
.shim {
  background: radial-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
  z-index: -1;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.fadeIn {
  animation: fadeIn 1s;
}

.mt0 {
  margin-top: 0;
}
.mb0 {
  margin-bottom: 0;
}
.m20 {
  margin: 20px;
}

.amount {
  margin-top: 30px;
}

@keyframes scaleIn {
  from {
    transform: scale3d(1.2, 1.2, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes fadeIn {
  from {
    transform: translate3d(0px, 60px, 0px);
    opacity: 0;
  }

  to {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
  }
}

.game-history-buttons {
  position: absolute;
  top: 80px;
  right: 32px;
}

.heading {

  font-size: 80px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 5px;
  text-align: center;
  margin-top: 40px;
  width: 60vw;
}

.sub-heading {
  font-family: Rogue, sans-serif;
  color: hsla(0, 0%, 100%, 0.84);
  font-weight: 100;
  letter-spacing: 6px;
  margin-bottom: 0;
  font-size: 22px;
}

.loading-spinner img {
  max-width: 140px;
}

.top-bar {
  margin-bottom: 1rem;
  position: fixed;
  top: 0;
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(33,37,41,1);
  z-index: 1;
}

.top-bar-icon {
  margin-left: 8px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-bar-icon a {
  padding: 0 5px;
  color: white;
  text-decoration: none;
}

.top-bar-icon a:hover {
  color: grey;
}

.top-bar-address {
  margin-right: 32px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.status {
  text-align: center;
}

.message-container {
  margin-bottom: 1rem;
  position: fixed;
  top: 65px;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  background: #bd2323;
}

.success-message {
  background: #1bb37b;
}

.buttons-left {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  margin-right: 2px;
}

.buttons-middle {
  border-radius: 0;
  margin-right: 2px;
}

.buttons-right {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-right: 2px;
}

button.button-selected {
  background-color: #F44336 !important;
  color: #ffffff;
}

.button-view-transaction {
  margin-bottom: .3em;
}

.stake-buttons-container {
  margin-top: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.stake-buttons-container > button {
  margin-top: 2px;
}

.token-info-container {
  background-color: #212121;
  border-radius: 3px;
  display: flex;
  min-height: 290px;
  width: 180px;
  flex-direction: column;
  font-family: "RoboGrotesk", sans-serif;
}

.token-name-label {
  margin: .3em;
  margin-left: .3em;
}

.token-info-label {
  padding: 0 .6em .3em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 1.3;
  font-size: small;
}

.total-staked-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.total-staked-container > h2 {
  font-family: "RoboGrotesk", sans-serif;
  font-size: 1.8rem;
  margin-top: 0;
  margin-bottom: 10px;
}

.token-stats {
  display: flex;
  width: 100%;
  background-color: #3296E5;
  background: linear-gradient(#2e2e2e, #161616);
  border-radius: 3px;
  padding: 2rem;
  margin-bottom: 2rem;
  flex-direction: column;
  text-align: center;
}

.total-supply-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.total-supply-container > h2 {
  font-family: "RoboGrotesk", sans-serif;
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.token-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.token-card {
  border-radius: 3px;
  padding: .2em;
  margin: .2em;
}

.token-card:hover {
  cursor: pointer;
}

.token-info-container img {
  margin: .3em 0 .6em;
}

.token-card-selected {
  background-color: #F44336;
}

.token-card-un-selected {
  background-color: transparent;
}

.token-list-container {
  margin-top: 100px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.token-list-buttons-container {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.token-list-buttons-container > div {
  margin-top: 2px;
  width: 50%
}

.top-buttons-container-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top-buttons-container-right button {
  background-color: #212121;
}

.no-items-container {
  margin-top: 120px;
  margin-bottom: 60px;
}

.tokens-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.game-info-text {
  max-width: 50vw;
  margin-bottom: 40px;
}

@media screen and (max-width: 991px) {
  .heading {
    font-size: 84px;
  }
  .sub-heading {
    font-size: 20px;
  }
  .token-list-buttons-container {
    flex-direction: column;
  }
  .token-list-buttons-container > div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .total-staked-container {
    justify-content: center;
  }
  .total-staked-container > h2 {
    font-size: 1rem;
  }
  .total-supply-container {
    justify-content: center;
  }
  .total-supply-container > h2 {
    font-size: 1rem;
  }
  .token-actions {
    justify-content: center;
  }
  button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 767px) {
  .heading {
    font-size: 36px;
  }
  .container {
    padding: 0;
  }
  button {
    padding: 12px 28px;
    min-width: 100px;
  }
  .token-actions {
    justify-content: center;
  }
  .game-info-text {
    max-width: 70vw;
  }
}
@media screen and (max-width: 479px) {
  .heading {
    display: block;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    font-size: 24px;
    text-align: center;
  }
  .sub-heading {
    font-size: 18px;
  }
  .container {
    padding: 0rem;
  }
  button {
    padding: 12px 20px;
    min-width: 100px;
  }
  .token-list-container {
    margin-top: 100px;
    max-width: 100%;
  }
  .token-info-container {
    width: 140px;
    min-height: 265px;
  }
  .token-actions {
    justify-content: center;
  }
  .game-info-text {
    max-width: 90vw;
  }
  .top-bar-address {
    margin-right: 20px;
    text-align: right;
  }
}

.hidden {
  display: none;
}

.show {
  display: flex;
}

.history-modal {
  flex-direction: column;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.8);
  z-index: 999;
}

.history-loading {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.history-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 40px;
  margin-right: 20px;
}

.history-content {
  width: 90vw;
  height: 80vh;
  background-color: #141414;
  padding: 10px 0 40px;
  display: flex;
  flex-direction: column;
}

.history-items-container {
  /*background-color: #282c34;*/
  overflow-y: auto;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 40px;
}

.history-item {
  /*display: flex;*/
  /*flex-direction: row;*/
  /*width: 100%;*/
}

.history-close {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 40px;
}
.history-close:active,
.history-close:hover {
  background-color: #282c34;
  cursor: pointer;
}

.modal {
  width: 70vw;
}

.event-history-table {
  width: 100%;
  border: 1px solid #4e0904;
  border-collapse: collapse;
}

.event-column {
  border: 1px solid  #4e0904;
  /*display: flex;*/
  align-items: center;
  font-size: .9em;
  padding: 1em;
  /*margin: .1em;*/
  background-color: #141414;
  text-transform: uppercase;
  font-family: "RoboGrotesk", sans-serif;
  overflow-wrap: break-word;
}

.event-header {
  background-color: #F44336;
}

.event-block-number-column {
  width: 10%;
}

.event-type-column {
  width: 10%;
}

.event-address-column {
  width: 32%;
}

.event-hash-column {
  width: 50%;
}

.event-actions > a {
  font-family: "Akira-Expanded", sans-serif;
  color: #F44336;
  text-decoration: none;
}

.add-to-metamask-button {
  display: flex;
  align-items: center;
  font-family: "RoboGrotesk", sans-serif;
  padding:  0 1em;
  background-color: #F44336;
  height: 40px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 1rem;
}

.add-to-metamask-button > img {
  margin-left: .5em;
  margin-top: -1em;
  margin-bottom: -1em;
  height: 30px;
}

.add-to-metamask-button:active,
.add-to-metamask-button:hover {
  outline: 0;
  cursor: pointer;
  border-color: #b5160a;
  background-color: #b5160a;
}

.game-video {
  margin-bottom: 2em;
  max-width: 90vw;
  height: 100%;
}